import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';


Vue.use(Vuex)

const state = {
    authenticatedUser: null,
    sidebarShow: 'responsive',
    sidebarMinimize: false,
}

const mutations = {
    async login(username, password) {
        try {
            const response = await axios({
                url: '/authentication/login',
                data: { username, password }
            });
            
            if (response.status > 399) {
                throw new Error('Kullanıcı adı veya parola hatalı!');
            }

            state.authenticatedUser = repsonse.data;
            axios.defaults.headers['X-AccessToken'] = state.authenticatedUser.token;
        } catch (error) {
            throw new Error('Kullanıcı adı veya parola hatalı!');
        }
    },

    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },

    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },

    set(state, [variable, value]) {
        state[variable] = value
    }
}

export default new Vuex.Store({
    state,
    mutations
})