import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import CoreuiVue from '@coreui/vue';
import axios from 'axios';
import moment from 'moment';

import App from './App';
import router from './router';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';


const token = localStorage.getItem('accessToken');
axios.defaults.baseURL = '//api.rotaileshop.com/';
// axios.defaults.baseURL = '//localhost:5010/';
axios.defaults.headers['X-AccessToken'] = token || '';
if (!token || token.length === 0) {
    window.location.hash = '#/login'
}
axios.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
        router.push('/login');
    }
    throw error;
});

moment.locale('tr');

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    }
})
