import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Authentication
const Login = () => import('@/views/authentication/Login')

// Orders
const Orders = () => import('@/views/orders/OrderList');
const OrderDetail = () => import('@/views/orders/OrderDetail');
const WaitingForShipment = () => import('@/views/orders/WaitingForShipment');

// Products
const Products = () => import('@/views/products/ProductList');
const ProductDetail = () => import('@/views/products/ProductDetail');
const ProductCreate = () => import('@/views/products/ProductCreate');

// Surveillance
const SurveillanceCreate = () => import('@/views/surveillance/SurveillanceCreate');

// Settings
const MailTemplateList = () => import('@/views/mail-template/MailTemplateList');
const MailTemplateCreate = () => import('@/views/mail-template/MailTemplateCreate');
const MailTemplateDetail = () => import('@/views/mail-template/MailTemplateDetail');
const MailSend = () => import('@/views/mail-template/MailSend');
const SendedMailList = () => import('@/views/mail-template/SendedMailList');
const SendedMailDetail = () => import('@/views/mail-template/SendedMailDetail');

Vue.use(Router)

function configRoutes() {
    return [
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Anasayfa',
            component: TheContainer,
            children: [
                {
                    path: 'dashboard',
                    name: 'Anasayfa',
                    component: Dashboard
                },
                {
                    path: 'orders',
                    name: 'Siparişler',
                    redirect: '/orders/list',
                    component: { render(c) { return c('router-view') } },
                    children: [
                        {
                            path: 'list',
                            name: 'Liste',
                            component: Orders,
                        },
                        {
                            path: 'waiting-for-shipment',
                            name: 'Kargolanmayı Bekleyenler',
                            component: WaitingForShipment,
                        },
                        {
                            path: ':id',
                            name: 'Sipariş Detayı',
                            component: OrderDetail,
                        },
                    ]
                },
                {
                    path: 'products',
                    name: 'Ürünler',
                    redirect: '/products/list',
                    component: { render(c) { return c('router-view') } },
                    children: [
                        {
                            path: 'list',
                            name: 'Liste',
                            component: Products,
                        },
                        {
                            path: 'create',
                            name: 'Oluştur',
                            component: ProductCreate,
                        },
                        {
                            path: ':id',
                            name: 'Detay',
                            component: ProductDetail,
                        },
                    ]
                },
                {
                    path: 'surveillances',
                    name: 'Gözetimler',
                    redirect: '/surveillances/create',
                    component: { render(c) { return c('router-view') } },
                    props: route => ({ orderId: route.query.orderId }),
                    children: [
                        {
                            path: 'create',
                            name: 'Oluştur',
                            component: SurveillanceCreate,
                        },
                    ]
                },
                {
                    path: 'settings',
                    name: 'Sistem Ayarları',
                    redirect: '/settings/mail',
                    component: { render(c) { return c('router-view') } },
                    children: [
                        {
                            path: 'mail',
                            name: 'E-Posta',
                            redirect: '/settings/mail/list',
                            component: { render(c) { return c('router-view') } },
                            children: [
                                {
                                    path: 'list',
                                    name: 'E-Posta şemaları',
                                    component: MailTemplateList
                                },
                                {
                                    path: 'create',
                                    name: 'E-Posta şeması oluştur',
                                    component: MailTemplateCreate
                                },
                                {
                                    path: 'send',
                                    name: 'E-Posta gönder',
                                    component: MailSend
                                },
                                {
                                    path: ':id',
                                    name: 'E-Posta şeması düzenle',
                                    component: MailTemplateDetail
                                }
                            ]
                        },
                        {
                            path: 'sended-mail',
                            name: 'Gönderilen E-postalar',
                            redirect: '/settings/sended-mails/list',
                            component: { render(c) { return c('router-view') } },
                            children: [
                                {
                                    path: 'list',
                                    name: 'Gönderilen E-postalar',
                                    component: SendedMailList
                                },
                                {
                                    path: ':id',
                                    name: 'Gönderilen E-posta',
                                    component: SendedMailDetail
                                }
                            ]
                        }
                    ]
                },
            ]
        },
        {
            path: '/login',
            name: 'Giriş',
            component: Login,
        }
    ]
}


export default new Router({
    mode: 'hash',
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
})
